import React, { useEffect } from 'react'

import Settings from '_appSetup/Settings'

const Homepage = () => {
  useEffect(() => {
    if (window) window.location.href = Settings.PRIVACY_POLICY_URL
  }, [])

  return null
}

export default Homepage
